import { createDateTime } from "ndr-designsystem";
import { PlanningDataDetails } from "../../fixed/PlanningData/PlanningDataDetails";
import {
    fromDto as fromPlannedResourceTimeSeriesDto,
    PlannedResourceTimeSeriesDto,
} from "./PlannedResourceTimeSeriesDto";

export interface PlanningDataDetailsDto {
    dataSource: string;
    internalID: string;
    date?: string;
    createdAt?: string;
    timeSeries: PlannedResourceTimeSeriesDto[];
}

export const fromDto = (dto: PlanningDataDetailsDto): PlanningDataDetails => ({
    ...dto,
    date: dto.date ? createDateTime(dto.date) : undefined,
    createdAt: dto.createdAt ? createDateTime(dto.createdAt) : undefined,
    timeSeries: dto.timeSeries ? dto.timeSeries.map((ts) => fromPlannedResourceTimeSeriesDto(ts)) : undefined,
});
