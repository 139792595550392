import React, { ReactElement, useEffect, useState } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IntlProvider, load } from "@progress/kendo-react-intl";
import { LocalizationProvider } from "@mui/lab";
import DateAdapter from "@mui/lab/AdapterLuxon";
import deNumbers from "cldr-numbers-full/main/de/numbers.json";
import { Settings } from "luxon";
import enNumbers from "cldr-numbers-full/main/en/numbers.json";
import deDateFields from "cldr-dates-full/main/de/dateFields.json";
import deCaGregorian from "cldr-dates-full/main/de/ca-gregorian.json";
import { LoginOrForgot } from "ndr-designsystem";
import ProtectedRoutes from "./Routes/ProtectedRoutes";
import "./App.css";
import Home from "./Pages/Home/Home";
import Api from "./api/Api";
import { deleteCookie, getCookieValue } from "./utils";
import AccessResult from "./api/fixed/LoginResult";
import Config from "./app-config";
// eslint-disable-next-line import/order
import { useCookies } from 'react-cookie';
import { setPageNotFound } from "./features/appStateSlice";
import { useAppDispatch } from "./app/hooks";
import { setAllResources } from "./features/controllableResources/store/controllableResourcesSlice";
import VerifyAuth from "./Pages/Home/VerifyAuth";
import buildTitle from "./Components/titleHelper";


load(deNumbers, enNumbers, deDateFields, deCaGregorian);
Settings.defaultZone = "Europe/Berlin";

function App(): ReactElement {
    const [isAuth, setIsAuth] = useState<boolean | undefined>(undefined);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const [cookieB, removeCookieB] = useCookies(['token']);
    const { t, i18n } = useTranslation("common");
    const history = useHistory();
    const dispatch = useAppDispatch();

    const listenToHistoryChanges = (): void => {
        buildTitle(history.location.pathname);
        dispatch(setPageNotFound());
        const cookie = getCookieValue("token");
        if (!cookie) {
            setIsAuth(false);
            setErrorMessage("alert_messages.token_expired");
        }
    };

    const onLogin = async (data: { username?: string; password?: string }): Promise<void> => {
        setErrorMessage(undefined);
        setIsLoggingIn(true);

        // Clear existing token before attempting login
        deleteCookie("token");
        Api.setToken(null);

        const res = await Api.HandleLoginAPI(data.username!, data.password!);
        setIsLoggingIn(false);

        switch (res) {
            case AccessResult.SUCCESS:
                {
                    setIsAuth(true);
                    const { state } = history.location as { state: { from: { pathname: string } } };
                    if (state) {
                        history.replace(state.from.pathname);
                    } else {
                        history.replace("/");
                    }
                }
                break;
            case AccessResult.NO_ACCESS:
                setIsAuth(false);
                setErrorMessage("alert_messages.tenant_access_denied");
                break;
            case AccessResult.INCORRECT_DETAILS:
                setIsAuth(false);
                setErrorMessage("alert_messages.incorrect_credentials");
                break;
            case AccessResult.BAD_REQUEST:
                setIsAuth(false);
                cookieB.token = getCookieValue("token");
                removeCookieB("token", null, { path: '/', expires: new Date(-20) });
                setErrorMessage("alert_messages.incorrect_credentials");
                history.push("/login");
                break;
            default:
                setIsAuth(false);
                // eslint-disable-next-line no-alert
                window.alert("ERROR");
        }
    };

    const onReset = async (data: { username?: string }): Promise<void> => {
        setErrorMessage(undefined);
        setIsLoggingIn(true);
        console.log(data);
        setIsLoggingIn(false);
    };

    useEffect(() => {
        let callback: any;
        if (history) {
            buildTitle(history.location.pathname);
            callback = history.listen(listenToHistoryChanges);
        }
        if (!isAuth) {
            Api.setToken(null);
        }

        return () => (callback ? callback() : undefined);
        // un-needed
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history]);

    useEffect(() => {
        const tryLogin = async (): Promise<void> => {
            const cookie = getCookieValue("token");

            if (cookie != null) {
                Api.setToken(cookie);
                const accessResult = await Api.checkIfUserHasAccess();

                const clearTokenAndAuth = (): void => {
                    deleteCookie("token");
                    removeCookieB("token", null, { path: '/', expires: new Date(-20) });
                    Api.setToken(null);
                    setIsAuth(false);
                };

                if (accessResult.status === AccessResult.EXPIRED) {
                    setErrorMessage("alert_messages.token_expired");
                    clearTokenAndAuth();
                    return;
                }

                if (accessResult.status === AccessResult.NO_ACCESS) {
                    setErrorMessage("alert_messages.tenant_access_denied");
                    clearTokenAndAuth();
                    return;
                }

                if (accessResult.status === AccessResult.BAD_REQUEST) {
                    setErrorMessage("alert_messages.tenant_access_denied");
                    clearTokenAndAuth();
                    return;
                }

                if (accessResult.resources != null) {
                    dispatch(setAllResources(accessResult.resources));
                }

                setIsAuth(true);
            } else {
                setIsAuth(false);
            }
        };
        tryLogin();
    }, [history, t, dispatch]);

    if (isAuth === undefined) {
        return <VerifyAuth />;
    }

    const handleLanguageChange = async (lang: "en" | "de"): Promise<void> => {
        if (lang === "en") {
            await i18n.changeLanguage("en-gb");
        } else {
            await i18n.changeLanguage("de");
        }
    };

    const handleForgotClick = (): void => {
        history.replace("/forget");
    };

    return (
        <IntlProvider locale={i18n.language}>
            <LocalizationProvider locale={i18n.language} dateAdapter={DateAdapter}>
                <Switch>
                    <Route path="/forget">
                        {/* @ts-ignore */}
                        <LoginOrForgot
                            usernameLabel={t("Login.Username")}
                            usernamePlaceholder={t("Login.enterUsername")}
                            submitText={t("Login.Reset")}
                            onLanguageChange={!Config.language ? handleLanguageChange : undefined}
                            isPerformingActions={isLoggingIn}
                            errorMessage={errorMessage}
                            onSubmit={onReset}
                        />
                    </Route>
                    <Route path="/login">
                        <LoginOrForgot
                            redirectButtonText="Forgot"
                            redirectButtonClick={handleForgotClick}
                            usernameLabel={t("Login.Username")}
                            passwordLabel={t("Login.Password")}
                            usernamePlaceholder={t("Login.enterUsername")}
                            passwordPlaceholder={t("Login.enterPassword")}
                            submitText={t("Login.login")}
                            onLanguageChange={!Config.language ? handleLanguageChange : undefined}
                            isPerformingActions={isLoggingIn}
                            errorMessage={errorMessage}
                            onSubmit={onLogin}
                        />
                    </Route>
                    <ProtectedRoutes path="/" isAuth={isAuth} Component={Home} />
                </Switch>
            </LocalizationProvider>
        </IntlProvider>
    );
}

export default App;
